:root {
    --navbar-height: 5rem;
    --body-top: var(--navbar-height);

    --side-navbar-width: 18rem;
    --side-navbar-collapsed-width: 5rem;

    --color-white: #fff;

    --color-grey-1: #f3f4f6;
    --color-grey-2: #d1d5db;
    --color-grey-3: #9ca3af;

    --color-dark-0: #090b10;
    --color-dark-1: #121722;
    --color-dark-2: #18202b;
    --color-dark-3: #374151;
    --color-dark-4: #6b7280;

    --color-white: #fff;

    --color-grey-1: #f3f4f6;
    --color-grey-2: #d1d5db;
    --color-grey-3: #9ca3af;

    --color-dark-0: #080c14;
    --color-dark-1: #111827;
    --color-dark-2: #1f2937;
    --color-dark-3: #374151;
    --color-dark-4: #6b7280;

    --color-footer: var(--color-dark-2);

    --color-primary-container: var(--color-white);
    --color-secondary-container: var(--color-grey-2);
    --color-third-container: var(--color-grey-1);

    --color-outer-container: var(--color-grey-1);
    --color-inner-container: var(--color-white);

    --color-middle-container: var(--color-grey-2);

    --color-text-primary: var(--color-dark-1);
    --color-text-secondary: var(--color-dark-4);

    --font-family: "Almarai";

    --font-size-h1: 1.5rem;
    --font-size-h2: 1.25rem;
    --font-size-h3: 1.125rem;
    --font-size-normal: 0.938rem;
    --font-size-small: 0.813rem;
    --font-size-smaller: 0.75rem;
    --font-size-big: 2rem;
    --font-size-bigmax: 3rem;
    --font-size-bigmain: 4rem;

    --font-w-normal: 400;
    --font-w-medium: 600;
    --font-w-bold: 700;

    --transition-smooth: 0.4s;
    --transition-very-smooth: 0.6s;
    --transition-fast: 0.2s;

    --color-shadow-large: #0006;
    --color-shadow-medium: #0004;
    --color-shadow-small: #0002;

    --shadow-large: 20px var(--color-shadow-large);
    --shadow-medium: 10px var(--color-shadow-medium);
    --shadow-small: 5px var(--color-shadow-small);

    --shadow-large-d: 20px 4px var(--color-shadow-large);
    --shadow-medium-d: 10px 4px var(--color-shadow-medium);
    --shadow-small-d: 5px 3px var(--color-shadow-small);

    --border-radius-large: 1.3rem;
    --border-radius-medium: 1rem;
    --border-radius-small: 0.4rem;
}

html.darkmode,
body.darkmode {
    --color-primary-container: var(--color-dark-0);
    --color-secondary-container: var(--color-dark-2);
    --color-third-container: var(--color-dark-1);

    /* --color-outer-container: var(--color-dark-0);
    --color-inner-containe: var(--color-dark-2);
    --color-middle-container: var(--color-dark-1); */

    --color-outer-container: var(--color-dark-0);
    --color-inner-container: var(--color-dark-1);
    --color-middle-container: var(--color-dark-2);

    --color-text-primary: var(--color-white);
    --color-text-secondary: var(--color-grey-3);

    --color-shadow-large: #000;
    --color-shadow-medium: #000c;
    --color-shadow-small: #0005;
}
@media screen and (min-width: 768px) {
    :root {
        --font-size-h1: 2rem;
        --font-size-h2: 1.5rem;
        --font-size-h3: 1.25rem;
        --font-size-normal: 1rem;
        --font-size-small: 0.875rem;
        --font-size-smaller: 0.813rem;
        --font-size-big: 3rem;
        --font-size-bigmax: 4rem;
        --font-size-bigmain: 6rem;
    }
}

body {
    /* scroll-behavior: smooth; */
    font-family: var(--font-family);
    font-weight: var(--font-w-normal);
    font-size: var(--font-size-normal);
    background-color: var(--color-primary-container);
    color: var(--color-text-primary);
    overflow-x: hidden;
    text-align: right;
    direction: rtl;
    margin-top: var(--body-top);
    padding-right: 0px !important;
}

body.collapsed-side-nav {
    --side-navbar-width: var(--side-navbar-collapsed-width);
}

body.modal-open {
    overflow-y: auto !important;
}

ul,
li {
    list-style: none;
}

label,
h1,
h2,
h3,
h4,
h5,
p,
ul,
li,
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* a {
    text-decoration: none;
    color: var(--color-text-primary);
    transition: all var(--transition-smooth) ease;
}

a:hover {
    text-decoration: none;
    color: var(--color-text-primary);
} */

.hover-shadow:not(.not-allowed):hover {
    transform: scale(1.03);
    box-shadow: 0px 7px 10px 0px #0003;
}
.hover-shadow-large:not(.not-allowed):hover {
    transform: scale(1.03);
    box-shadow: -7px 15px 40px -5px var(--color-shadow-large);
}
.group:hover .group-hover\:shadow:not(.not-allowed) {
    transform: scale(1.03);
    box-shadow: 0px 7px 10px 0px #0003;
}
.group:hover .group-hover\:shadow-large:not(.not-allowed) {
    transform: scale(1.03);
    box-shadow: -7px 15px 40px -5px var(--color-shadow-large);
}

.ar {
    direction: rtl;
    text-align: right;
}
.en {
    direction: ltr;
    text-align: left;
}
.ltr {
    direction: ltr;
}
.rtl {
    direction: rtl;
}

.smooth {
    transition: all var(--transition-smooth) ease;
}
.smooth-slow {
    transition: all var(--transition-very-smooth) ease;
}
.smooth-fast {
    transition: all var(--transition-fast) ease;
}

.shadow-large {
    box-shadow: 0px 0px var(--shadow-large);
}
.shadow-large--oblique {
    box-shadow: -5px 5px var(--shadow-large);
}
.shadow-medium {
    box-shadow: 0px 0px var(--shadow-medium);
}
.shadow-medium--oblique {
    box-shadow: -3px 3px var(--shadow-medium);
}
.shadow-small {
    box-shadow: 0px 0px var(--shadow-small);
}
.shadow-small--oblique {
    box-shadow: -1px 1px var(--shadow-small);
}

.me-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(
        center,
        ellipse,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
}
.me-shadow:hover:before,
.me-shadow:focus:before,
.me-shadow:active:before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
}

.rounded-circle {
    border-radius: 100%;
}

.divider-1 {
    width: 100%;
    height: 1px;
    /* background-color: var(--color-text-secondary); */
}
.divider-2 {
    width: 100%;
    height: 2px;
    /* background-color: var(--color-text-secondary); */
}
.divider-3 {
    width: 100%;
    height: 3px;
    /* background-color: var(--color-text-secondary); */
}

.nice_scroll::-webkit-scrollbar {
    width: var(--scroll-width);
    transition: all 1s ease;
}

.nice_scroll::-webkit-scrollbar-track {
    background: var(--color-primary-container);
}

.nice_scroll::-webkit-scrollbar-thumb {
    /* background: var(--color-rose-light-2); */
    background: #075985;
}

.glassy {
    --box-border: #ffffff40;
    --back-color: #0001;
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px);
    border-top: 1px solid var(--box-border);
    border-left: 1px solid var(--box-border);
    background-color: var(--back-color);
}

.darkmode .glassy {
    --box-border: #fff3;
    --back-color: #fff1;
}

.non-blur-glassy {
    --box-border: #ffffff40;
    --back-color: #0001;
    /* -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px); */
    border-top: 1px solid var(--box-border);
    border-left: 1px solid var(--box-border);
    background-color: var(--back-color);
}

.darkmode .non-blur-glassy {
    --box-border: #fff3;
    --back-color: #fff1;
}

.glassy--border {
    --box-border: #dfdfdf;
    --back-color: #fff2;
    border-top: 4px solid var(--box-border);
    border-left: 4px solid var(--box-border);
    /* background-color: var(--back-color); */
}

.darkmode .glassy--border {
    --box-border: #fff3;
    --back-color: #fff1;
}

.glassy\:border-1 {
    --box-border: #dfdfdf;
    --back-color: #fff2;
    border-top: 1px solid var(--box-border);
    border-left: 1px solid var(--box-border);
    /* background-color: var(--back-color); */
}

.darkmode .glassy\:border-1 {
    --box-border: #fff3;
    --back-color: #fff1;
}

/* ANIMATIONS */

@keyframes toSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes shake {
    from {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    to {
        transform: rotate(0deg);
    }
}
.tospin {
    --duration: 5000ms;
    animation-name: toSpin;
    animation-duration: var(--duration);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.spin-duration-slow {
    --duration: 15000ms;
}
.spin-duration-xslow {
    --duration: 30000ms;
}
/* DISPLAY FLEX */
.mobile-row-col {
    display: flex;
    flex-direction: column;
}
.mobile-flex-center-both {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (min-width: 768px) {
    .mobile-row-col {
        flex-direction: row;
    }
    .mobile-flex-center-both {
        display: flex;
        align-items: unset;
        justify-content: unset;
    }
}

.flex-center-x {
    display: flex;
    justify-content: center;
}
.flex-center-y {
    display: flex;
    align-items: center;
}
.flex-center-both {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-item {
    flex: 1;
}

/* TYPOGRAPHY */
.font-ruqaa {
    font-family: "Aref Ruqaa", serif;
}
.font-cairo {
    font-family: "Cairo", sans-serif;
}
.font-alm {
    font-family: "Almarai", sans-serif;
}
.font-taj {
    font-family: "tajawal", sans-serif;
}
.font-com {
    font-family: "Comfortaa", sans-serif;
}
.font-rubik {
    font-family: "Rubik", sans-serif;
}
.font-ibm {
    font-family: "IBM Plex Sans Arabic", sans-serif;
}
.font-kuf {
    font-family: "Kufam", sans-serif;
}
.font-comforter {
    font-family: "Comforter", cursive;
}

.font-big {
    font-size: var(--font-size-big);
}
.font-bigmax {
    font-size: var(--font-size-bigmax);
}
.font-bigmain {
    font-size: var(--font-size-bigmain);
}
.font-normal {
    font-size: var(--font-size-normal);
}
.font-small {
    font-size: var(--font-size-small);
}
.font-smaller {
    font-size: var(--font-size-smaller);
}
.font-h1 {
    font-size: var(--font-size-h1);
}
.font-h2 {
    font-size: var(--font-size-h2);
}
.font-h3 {
    font-size: var(--font-size-h3);
}
.font-w-bold {
    font-weight: var(--font-w-bold);
}
.font-w-medium {
    font-weight: var(--font-w-medium);
}
.font-w-normal {
    font-weight: var(--font-w-normal);
}

/* SHADOWS */

/* OTHERS */

/* COLORS */
.bg-outer-container {
    background-color: var(--color-outer-container);
}
.clr-outer-container {
    color: var(--color-outer-container);
}
.bg-inner-container {
    background-color: var(--color-inner-container);
}
.clr-inner-container {
    color: var(--color-inner-container);
}

.fill-text-primary {
    fill: var(--color-text-primary);
}
.bg-text-primary {
    background-color: var(--color-text-primary);
}
.clr-text-primary {
    color: var(--color-text-primary);
}
.bg-text-secondary {
    background-color: var(--color-text-secondary);
}
.clr-text-secondary {
    color: var(--color-text-secondary);
}
.bg-text-third {
    background-color: var(--color-text-third);
}
.clr-text-third {
    color: var(--color-text-third);
}

.fill-primary-container {
    fill: var(--color-primary-container);
}
.clr-primary-container {
    color: var(--color-primary-container);
}
.clr-secondary-container {
    color: var(--color-secondary-container);
}
.clr-third-container {
    color: var(--color-third-container);
}
.bg-primary-container {
    background-color: var(--color-primary-container);
}
.bg-secondary-container {
    background-color: var(--color-secondary-container);
}
.bg-third-container {
    background-color: var(--color-third-container);
}
.border-primary-container {
    border-color: var(--color-primary-container);
}
.border-secondary-container {
    border-color: var(--color-secondary-container);
}
.border-third-container {
    border-color: var(--color-third-container);
}

.hover\:bg-outer-container:hover {
    background-color: var(--color-outer-container);
}
.hover\:clr-outer-container:hover {
    color: var(--color-outer-container);
}
.hover\:bg-inner-container:hover {
    background-color: var(--color-inner-container);
}
.hover\:clr-inner-container:hover {
    color: var(--color-inner-container);
}

.hover\:bg-text-primary:hover {
    background-color: var(--color-text-primary);
}
.hover\:clr-text-primary:hover {
    color: var(--color-text-primary);
}
.hover\:bg-text-secondary:hover {
    background-color: var(--color-text-secondary);
}
.hover\:clr-text-secondary:hover {
    color: var(--color-text-secondary);
}
.hover\:bg-text-third:hover {
    background-color: var(--color-text-third);
}
.hover\:clr-text-third:hover {
    color: var(--color-text-third);
}

.hover\:clr-primary-container:hover {
    color: var(--color-primary-container);
}
.hover\:clr-secondary-container:hover {
    color: var(--color-secondary-container);
}
.hover\:clr-third-container:hover {
    color: var(--color-third-container);
}
.hover\:bg-primary-container:hover {
    background-color: var(--color-primary-container);
}
.hover\:bg-secondary-container:hover {
    background-color: var(--color-secondary-container);
}
.hover\:bg-third-container:hover {
    background-color: var(--color-third-container);
}

.clr-white {
    color: var(--color-white);
}
.bg-white {
    background-color: var(--color-white);
}

.hover\:clr-white:hover {
    color: var(--color-white);
}
.hover\:bg-white:hover {
    background-color: var(--color-white);
}

/* ###### -----NEW------ ######### */

.navbar-complement-height {
    height: calc(100vh - (var(--body-top)));
}

.negative-nav-margin {
    margin-top: calc(-1 * var(--navbar-height));
}
.top-nav-margin {
    top: (var(--navbar-height));
}
.posisitve-nav-padding-top {
    padding-top: var(--navbar-height);
}
.posisitve-nav-padding-bottom {
    padding-bottom: var(--navbar-height);
}
.pb-nav-margin {
    padding-bottom: var(--navbar-height);
}
.completing-line::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--color-text-primary);
    transform: all var(--transition-smooth) ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    left: 0px;
}
.flip-vertical {
    --tw-scale-y: -1;
}
.flip-horizontal {
    --tw-scale-x: -1;
}
.hw-screen {
    height: 100vh;
    width: 100vh;
}
/* .line-right::before {
    content: "";
    position: absolute;
    right: 0px;
    top: 0px;
    width: 3px;
    height: 100%;
    background-color: theme("colors.purple.800");
} */
.hover\:line-right::before {
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 3px;
    height: 0%;
    background-color: currentColor;
    transition: all var(--transition-smooth) ease;
}
.hover\:line-right:hover::before {
    top: 0px;
    height: 100%;
    transform: translateX(0.5rem);
}

.hover\:line-left::before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 3px;
    height: 0%;
    background-color: currentColor;
    transition: all var(--transition-smooth) ease;
}
.hover\:line-left:hover::before {
    top: 0px;
    height: 100%;
    transform: translateX(0.5rem);
}

.custom_shadow {
    box-shadow: 0px 15px 30px -10px theme("colors.cyan.700");
}
.darkmode .custom_shadow {
    box-shadow: 0px 15px 30px -10px theme("colors.cyan.600");
}
